/** @type {HTMLTemplateElement} */
const summaryTemplate = document.querySelector(`body > main > ol > template`);

class SummaryElement extends HTMLElement {
	constructor() {
		super();
		this.appendChild(summaryTemplate.content.cloneNode(true));
	}

	/**
	 * @param titleName {string}
	 */
	set titleName(titleName) {
		const titleElement = this.querySelector(`header > h4`);
		titleElement.innerHTML = titleName;
	}

	/**
	 * @param description {string}
	 */
	set description(description) {
		const descriptionElement = this.querySelector(`p`);
		descriptionElement.textContent = description;
	}

	/**
	 * @param creator {string}
	 */
	set creator(creator) {
		const creatorElement = this.querySelector(`address`);
		creatorElement.innerHTML = creator;
	}

	/**
	 * @param publishedAt {Date}
	 */
	set publishedAt(publishedAt) {
		const publishedAtElement = this.querySelector(`time`);
		publishedAtElement.innerHTML = publishedAt
			.toLocaleDateString(undefined, {year: `numeric`, month: `short`, day: `numeric`});
	}

	/**
	 * @param isRead {boolean}
	 */
	set isRead(isRead) {
		this.isReadElement = this.querySelector(`header > input[type=checkbox]`);
		this.isReadElement.checked = isRead;
	}

	/**
	 * @param slot {Element}
	 */
	set slot(slot) {
		this.querySelector(`slot`).appendChild(slot);
	}
}

customElements.define(`recess-summary`, SummaryElement, {extends: `article`});
